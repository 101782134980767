<template>
  <div class="section1">
    <div class="w-full lg:pt-10 pt-0 flex flex-col items-center lg:hidden">
   <!--     <img loading="lazy"
        data-aos="fade-up"
        class="w-1/3 mb-10 logonew"
        src="./s1/logo.svg"
        alt=""
      />
   <div class="w-full relative">
        <img loading="lazy"
          data-aos="fade-in"
          class="w-full lg:mt-10 mt-0"
          src="./s1/style.svg"
          alt=""
        />
        <img loading="lazy"
          data-aos="fade-up"
          class="absolute lg:w-1/6 w-1/10 ballon-animate"
          src="./s1/balloon.png"
          alt=""
        />
      </div>
      <img loading="lazy"
        data-aos="fade-up"
        class="lg:w-1/4 w-1/5 -mt-5"
        src="./s1/text.svg"
        alt=""
      />
    </div>
    <div class=" hidden lg:flex w-full h-screen">
      <img loading="lazy"
        data-aos="fade-up"
        class="w-1/5"
        style="margin-left:20%"
        src="./s1/logo.svg"
        alt=""
      />
      <div class="w-full flex flex-col-reverse">
        <div class="relative">
          <img loading="lazy"
            data-aos="fade-in"
            class="w-full"
            src="./s1/style_pc.svg"
            alt=""
          />
          <img loading="lazy"
            data-aos="fade-up"
            class="absolute ballon-animate w-1/6 "
            src="./s1/balloon.png"
            alt=""
          />
        </div>
        <img loading="lazy"
          data-aos="fade-up"
          class="lg:w-1/6 xl:w-1/10 self-end"
          style="margin-right:15%;margin-top:10%"
          src="./s1/text_pc.svg"
          alt=""
        />
      </div>  -->
    </div>
    <!-- <img loading="lazy" src="./s1/line.svg" alt="" /> -->
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
.section1 {
  min-height: 100vh;
  // background-color: rgba($color: #231815, $alpha: 0.25);
  background-color: #fff100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./s1/bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
  position: relative;
  background-color: #210000;
  background-repeat: no-repeat;

  .logo {
    position: relative;
    width: calc(536 * 100vw / 1920);
    top: calc(50% + (280 - 540) * 100vw / 1920);
    margin: 0 auto calc(105 * 100vw / 1920) auto;
    display: block;
  }
  .st0 {
    clip-path: url(#SVGID_2_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st1 {
    clip-path: url(#SVGID_4_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st2 {
    clip-path: url(#SVGID_6_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st3 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 8.7;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st4 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st5 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st6 {
    clip-path: url(#SVGID_10_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st7 {
    clip-path: url(#SVGID_12_);
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st8 {
    clip-path: url(#SVGID_14_);
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st9 {
    clip-path: url(#SVGID_16_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st10 {
    clip-path: url(#SVGID_18_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st11 {
    clip-path: url(#SVGID_20_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 17;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    clip-path: url(#SVGID_22_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st13 {
    clip-path: url(#SVGID_24_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st14 {
    clip-path: url(#SVGID_26_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 17;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st15 {
    clip-path: url(#SVGID_28_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st16 {
    clip-path: url(#SVGID_30_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st17 {
    clip-path: url(#SVGID_32_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 21;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st18 {
    clip-path: url(#SVGID_34_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st19 {
    clip-path: url(#SVGID_34_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st20 {
    clip-path: url(#SVGID_36_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 19;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st21 {
    clip-path: url(#SVGID_38_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st22 {
    fill: #f3b2c1;
    animation: op 0.3s 8s linear reverse both;
  }
  .l01 {
    stroke-dasharray: 170;
    stroke-dashoffset: 170;
    animation: letterDraw 0.17s 0s linear forwards;
  }
  .l02 {
    stroke-dasharray: 135;
    stroke-dashoffset: 135;
    animation: letterDraw 0.135s 0.17s linear forwards;
  }
  .l03 {
    stroke-dasharray: 135;
    stroke-dashoffset: 135;
    animation: letterDraw 0.135s 0.305s linear forwards;
  }
  .l04 {
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    animation: letterDraw 0.06s 0.44s linear forwards;
  }
  .l05 {
    stroke-dasharray: 47;
    stroke-dashoffset: 47;
    animation: letterDraw 0.047s 0.5s linear forwards;
  }
  .l06 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: letterDraw 0.15s 0.547s linear forwards;
  }
  .l07 {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: letterDraw 0.05s 0.697s linear forwards;
  }
  .l08 {
    stroke-dasharray: 165;
    stroke-dashoffset: 165;
    animation: letterDraw 0.165s 0.747s linear forwards;
  }
  .l09 {
    stroke-dasharray: 92;
    stroke-dashoffset: 92;
    animation: letterDraw 0.092s 0.912s linear forwards;
  }
  .l10 {
    stroke-dasharray: 36;
    stroke-dashoffset: 36;
    animation: letterDraw 0.036s 1.004s linear forwards;
  }
  .l11 {
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: letterDraw 0.048s 1.04s linear forwards;
  }
  .l12 {
    stroke-dasharray: 260;
    stroke-dashoffset: 260;
    animation: letterDraw 0.26s 1.088s linear forwards;
  }
  .l13 {
    stroke-dasharray: 67;
    stroke-dashoffset: 67;
    animation: letterDraw 0.067s 1.348s linear forwards;
  }
  .l14 {
    stroke-dasharray: 110;
    stroke-dashoffset: 110;
    animation: letterDraw 0.11s 1.415s linear forwards;
  }
  .l15 {
    stroke-dasharray: 26;
    stroke-dashoffset: 26;
    animation: letterDraw 0.026s 1.525s linear forwards;
  }
  .l16 {
    stroke-dasharray: 28;
    stroke-dashoffset: 28;
    animation: letterDraw 0.028s 1.551s linear forwards;
  }
  .l17 {
    stroke-dasharray: 16;
    stroke-dashoffset: 16;
    animation: letterDraw 0.016s 1.579s linear forwards;
  }
  .l18 {
    stroke-dasharray: 72;
    stroke-dashoffset: 72;
    animation: letterDraw 0.072s 1.595s linear forwards;
  }
  .l19 {
    stroke-dasharray: 86;
    stroke-dashoffset: 86;
    animation: letterDraw 0.086s 1.667s linear forwards;
  }
  .l20 {
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
    animation: letterDraw 0.12s 1.753s linear forwards;
  }
  .l21 {
    stroke-dasharray: 320;
    stroke-dashoffset: 320;
    animation: letterDraw 0.32s 1.873s linear forwards;
  }
  .l22 {
    stroke-dasharray: 107;
    stroke-dashoffset: 107;
    animation: letterDraw 0.107s 2.193s linear forwards;
  }
  .l23 {
    stroke-dasharray: 82;
    stroke-dashoffset: 82;
    animation: letterDraw 0.082s 2.3s linear forwards;
  }
  .l24 {
    stroke-dasharray: 17;
    stroke-dashoffset: 17;
    animation: letterDraw 0.017s 2.382s linear forwards;
  }
  .l25 {
    stroke-dasharray: 210;
    stroke-dashoffset: 210;
    animation: letterDraw 0.21s 2.399s linear forwards;
  }

  .t {
    top: calc(50% + (280 - 540) * 100vw / 1920);
    position: relative;
    width: calc(1275 * 100vw / 1920);
    margin: auto;
    display: block;
    animation: op 0.3s 8.2s linear reverse both;
  }
  .ballon-animate {
    top: -30%;
    left: 50%;
    animation: ballon 6s linear infinite;
  }
  @keyframes ballon {
    0% {
      top: -30%;
    }
    50% {
      top: -45%;
    }
    100% {
      top: -30%;
    }
  }
  @keyframes letterDraw {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes op {
    to {
      opacity: 0;
    }
  }

  .mo {
    display: none;
  }

  .intro {
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    .i1 {
      font-size: size(65.5);
      font-weight: 500;
      letter-spacing: size(5);
      font-family: 'Noto Serif TC', Noto Sans TC, serif;
      white-space: nowrap;
    }
    .i2 {
      margin-top: size(40);
      width: size(454);
      img {
        width: 100%;
      }
    }
    .i3 {
      margin-top: size(5);
      font-family: 'Noto Serif TC';
      font-size: size(38);
      font-weight: 100;
      span {
        &.flicking {
          animation: flicking 2s;
        }
      }

      @keyframes flicking {
        from {
          opacity: 1;
        }
        to {
          opacity: 0.2;
        }
      }
    }
    .i4 {
      margin-top: size(40);
      font-size: size(36);
      letter-spacing: size(5);
      font-family: 'Noto Serif TC', Noto Sans TC, serif;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section1 {
    min-height: size-m(748);
    // background-color: rgba($color: #231815, $alpha: 0.25);
    background-color: #fff100;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url('../rss/s1/bg_mo.jpg');
    background-image: url('./s1/mo.jpg');
    background-position: 50%;
    background-size: cover;
    background-attachment: inherit;

    .logo {
      width: calc(197 * 100vw / 375);
      top: calc(50% + (299 - 540) * 100vw / 375);
      margin: 0 auto calc(45 * 100vw / 375) auto;
    }

    .logonew {
      width: calc(180 * 100vw / 375) !important;
      margin-top: 30px !important;
    }
    .mo .st0 {
      clip-path: url(#SVGID_2_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st1 {
      clip-path: url(#SVGID_4_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st2 {
      clip-path: url(#SVGID_6_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st3 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 8.7;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st4 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st5 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st6 {
      clip-path: url(#SVGID_10_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 12;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st7 {
      clip-path: url(#SVGID_12_m);
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st8 {
      clip-path: url(#SVGID_14_m);
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st9 {
      clip-path: url(#SVGID_16_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st10 {
      clip-path: url(#SVGID_18_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st11 {
      clip-path: url(#SVGID_20_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 17;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st12 {
      clip-path: url(#SVGID_22_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st13 {
      clip-path: url(#SVGID_24_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st14 {
      clip-path: url(#SVGID_26_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 17;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st15 {
      clip-path: url(#SVGID_28_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st16 {
      clip-path: url(#SVGID_30_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st17 {
      clip-path: url(#SVGID_32_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 21;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st18 {
      clip-path: url(#SVGID_34_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st19 {
      clip-path: url(#SVGID_34_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st20 {
      clip-path: url(#SVGID_36_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 19;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st21 {
      clip-path: url(#SVGID_38_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 15;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .t {
      top: calc(50% + (299 - 540) * 100vw / 375);
      width: calc(260 * 100vw / 375);
    }
    .mo {
      display: block;
    }
    .pc {
      display: none;
    }

    .view-pc {
      display: none;
    }
    .intro {
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      .i1 {
        font-size: size-m(30);
        font-weight: 500;
        letter-spacing: size(5);
        font-weight: 600;
      }
      .i2 {
        margin-top: size-m(40);
        width: size-m(150);
        img {
          width: 100%;
        }
      }
      .i3 {
        margin-top: size-m(5);
        font-family: 'Noto Serif TC';
        font-size: 12px;
        font-weight: 100;
        transform: scale(0.8) !important;
        span {
          &.flicking {
            animation: flicking 2s;
          }
        }

        @keyframes flicking {
          from {
            opacity: 1;
          }
          to {
            opacity: 0.2;
          }
        }
      }
      .i4 {
        margin-top: size-m(40);
        font-size: size-m(23);
        line-height: 1.5;
        letter-spacing: size-m(2);
        text-align: center;
        font-weight: 600;
      }
      .i5 {
        margin-top: size-m(3);
        font-size: size-m(21.5);
        line-height: 1.5;
        letter-spacing: size-m(2);
        text-align: center;
        font-family: 'Noto Serif TC', Noto Sans TC, serif;
        font-weight: 600;
      }
    }
  }

  .ballon-animate {
    top: 0;
    left: 50%;
    animation: ballon 6s linear infinite;
  }
  @keyframes ballon {
    0% {
      top: 20px;
    }
    50% {
      top: -20px;
    }
    75% {
      top: 10px;
    }
    100% {
      top: 20px;
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isMobile
    }
  },

  methods: {
    getRandom(arr, n) {
      var result = new Array(n)
      var len = arr.length
      var taken = new Array(len)
      if (n > len) {
        throw new RangeError('getRandom: more elements taken than available')
      }
      while (n--) {
        var x = Math.floor(Math.random() * len)
        result[n] = arr[x in taken ? taken[x] : x]
        taken[x] = --len in taken ? taken[len] : len
      }
      return result
    },
    setAnimateToEn() {
      const target = this.$refs.en.querySelectorAll('span')

      let random = this.getRandom(target, 5)

      random.forEach((element, i) => {
        setTimeout(() => {
          element.classList.remove('flicking')
          element.classList.add('flicking')
        }, i * 500)
      })
    }
  },

  mounted() {
    let interval
    // interval = setInterval(() => {
    //   this.setAnimateToEn()
    // }, 1000)

    setTimeout(() => {
      clearInterval(interval)
    }, 3000)
  },

  created() {}
}
</script>
