<template>
  <div class="section4">
    <div class="swiper-box" data-aos="fade-in">
      <div class="bg-img">
        <h2
          class="text-xl lg:text-5xl text-primary-green-200 font-primary font-medium lg:mb-10 mb-4"
        >
          {{ title }}
        </h2>
        <div
          v-html="content"
          class="mb-10 lg:mb-0 lg:mx-auto lg:text-2xl lg:leading-primary leading-8 p"
        />
      </div>
      <div v-if="isMobile" class="flex justify-center mb-4">
        <img loading="lazy"
          class="w-3"
          :src="require('./s4/left_arrow_mo.svg')"
          alt=""
          @click="prev()"
        />
        <div
          class="mx-5"
          style="height: 20px; border-right: 2px solid #727171; width: 0"
        ></div>
        <img loading="lazy"
          class="w-3"
          :src="require('./s4/right_arrow_mo.svg')"
          alt=""
          @click="next()"
        />
      </div>
      <div class="lg:pr-14 lg:pb-28 lg:pl-14">
        <swiper
          :options="swiperOptions"
          ref="swiperSection4"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
          <div v-if="!isMobile" class="swiper-button-prev" slot="button-prev">
            <img loading="lazy" src="./s3/left_arrow.svg" alt="" />
          </div>
          <div v-if="!isMobile" class="swiper-button-next" slot="button-next">
            <img loading="lazy" src="./s3/right_arrow.svg" alt="" />
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

/* 螢幕尺寸標準 */
.section4 {
  background-color: #fff100;
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .bg-img {
    background-image: url('./s4/style_line_pc.svg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 50%;
    padding: 10% 0;
  }
  .info {
    position: relative;
    text-align: left;
    width: size(440);
    margin-right: size(80);
    h2 {
      text-align: center;
      font-size: size(55);
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 5px;
      color: #00693e;
      white-space: nowrap;
      font-family: Noto Sans TC, serif;
    }
    .divider {
      background: #009be4;
      width: size(560);
      height: 1px;
      margin: size(20) 0;
      margin-right: -#{size(80)};
    }
    &::after {
      content: '';
      width: 1px;
      height: size(400);
      background: #267f98;
      position: absolute;
      top: -#{size(450)};
      left: 50%;
    }
  }
  .swiper-box {
    width: 100%;
    .swiper-container {
      // padding: size(80) 0;
      // margin: size(100) 0;
      .slide {
        // height: 0;
        // height: 1000px;
        height: 89vh;
        // padding-bottom: 66%;
        background-size: cover;
        .caption {
          position: absolute;
          z-index: 1;
          color: #fff;
          right: 3vw;
          bottom: 2vw;
          font-size: 16px;
          font-weight: 500;
          text-shadow: #000 0.1em 0.1em 0.1em;
        }
      }
      .swiper-button-prev {
        // left: size(500);
        left: 0;
        bottom: 0;
        width: size(80);
        height: size(80);
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }
      .swiper-button-next {
        right: 0;
        color: #fff;
        width: size(80);
        height: size(80);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }

      .swiper-pagination {
        padding-left: size(650);
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section4 {
    background-color: #fff100;
    min-height: unset;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    // padding-top: size-m(50);

    .p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
      }

    h2 {
        text-align: center;
        font-size: size-m(18);
        font-weight: 600;
        line-height: 1.5;
        font-family: Noto Sans TC, serif;
        letter-spacing: 3px;
      }

    .bg-img {
      background-image: url('./s4/style_line.svg');
      background-repeat: no-repeat;
      background-size: 115%;
      background-position: 30% 55%;
      padding-top: 29%;
    }
    .info {
      text-align: left;
      width: 80%;
      margin-right: unset;
      margin-top: size-m(25);
      padding-top: size-m(25);
      height: auto;
      
      .divider {
        width: 1px;
        height: size-m(50);
        margin-right: 0;
        margin: size-m(25) auto size-m(15) auto;
      }
      p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
      }
      &::after {
        content: '';
        width: 80vw;
        height: 1px;
        background: #267f98;
        position: absolute;
        left: 0;
        transform: unset;
        top: 0%;
        opacity: 1;
        animation: fade 2s;

        @keyframes fade {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
    .swiper-box {
      margin-top: size-m(0);
      width: 100%;
      .swiper-container {
        margin-bottom: 3px;
        .slide {
          height: auto;
          padding-bottom: 66%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .caption {
            position: absolute;
            z-index: 1;
            color: #fff;
            right: 3vw;
            bottom: 3vw;
            font-size: 12px;
            font-weight: 500;
            text-shadow: #000 0.1em 0.1em 0.1em;
          }
        }
        .swiper-button-prev {
          left: size-m(150);
          top: 95%;
          width: size-m(40);
          height: size-m(40);
          color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;

          &::after {
            content: '';
            left: 10vw;
            top: 1.5vh;
            width: 3%;
            height: 46%;
            position: absolute;
            background-image: url('./s3/arrow_line.svg');
          }
        }
        .swiper-button-next {
          right: size-m(150);
          top: 95%;
          width: size-m(40);
          height: size-m(40);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }

        .swiper-pagination {
          padding: 0;
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size-m(10);
            height: size-m(10);
            margin: 0 size-m(2);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section4',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMobile,
      activeSlide: {},
      title: '／亞東生活圈 都心璀璨一等地／',
      content: `近亞東醫院站、湳雅夜市、台65線<br/>遠東科技園區 Google、亞馬遜國際企業插旗<br/>更近板泰輕軌預劃路線 連袂鼎起最耀眼將來`,
      slides: [
        {
          img: isMobile
            ? require('@/projects/rss/s4/slider_2_mo.jpg')
            : require('@/projects/rss/s4/slider_2.jpg'),
          caption: '捷運亞東醫院站',
        },
        {
          img: isMobile
            ? require('@/projects/rss/s4/slider_3_mo.jpg')
            : require('@/projects/rss/s4/slider_3.jpg'),
          caption: '遠東科技園區',
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section4 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection4.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]

      // setTimeout(() => {
      //   $('.section4 [data-aos]').each((i, val) => {
      //     $(val).addClass('aos-animate')
      //   })
      //   setTimeout(() => {
      //     $('.section4 .hasdivider').addClass('showin')
      //   }, 200)
      //   setTimeout(() => {
      //     $('.section4 .divider').addClass('showin')
      //   }, 400)
      // }, 0)
    },
    prev() {
      this.$refs.swiperSection4.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection4.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
    // if (isMobile) {
    //   this.slides.push({ img: require('@/projects/rss/s3/slider_3.jpg') })
    // }
  },

  created() {},
}
</script>
