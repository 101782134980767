<template>
  <div class="section3">
    <div class="swiper-box relative" data-aos="fade-in">
      <swiper
        :options="swiperOptions"
        ref="swiperSection3"
        @slideChangeTransitionStart="slidechange"
      >
        <swiper-slide
          class="slide"
          v-for="(slide, i) in slides"
          v-bind:key="i"
          v-bind:style="{
            backgroundImage: `url(${slide.img})`,
          }"
        >
          <div class="content" v-html="slide.content"></div>

          <div class="caption">
            {{ slide.caption }}
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div
      v-if="!isMobile"
      class="flex items-center z-10 absolute top-0 w-full justify-between h-full cursor-pointer"
      style="padding: 0 30px"
    >
      <img loading="lazy"
        class="w-10"
        :src="require('./s3/left_arrow.svg')"
        alt=""
        @click="prev()"
      />
      <img loading="lazy"
        class="w-10"
        :src="require('./s3/right_arrow.svg')"
        alt=""
        @click="next()"
      />
    </div>
    <div class="bg-img"></div>
    <div
      v-if="isMobile"
      class="flex justify-center items-center z-10 absolute bottom-4"
    >
      <img loading="lazy"
        class="w-3"
        :src="require('./s3/left_arrow.svg')"
        alt=""
        @click="prev()"
      />
      <div
        class="mx-5"
        style="height: 20px; border-right: 2px solid #fff; width: 0"
      ></div>
      <img loading="lazy"
        class="w-3"
        :src="require('./s3/right_arrow.svg')"
        alt=""
        @click="next()"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/style/function.scss';

/* 螢幕尺寸標準 */
.fontSize-1\.8vh {
    text-shadow: 0.1em 0.1em 0.2em black;
  }
.section3 {
  background-color: #d2dee0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .bg-img {
    background-image: url('./s5/style_line_pc.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: 105% 103%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .info {
    position: relative;
    text-align: left;
    width: size(440);
    margin-right: size(80);
    h2 {
      text-align: center;
      font-size: size(55);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 5px;
      color: #00693e;
      white-space: nowrap;
      font-family: Noto Sans TC, serif;
    }
    .divider {
      background: #009be4;
      width: size(560);
      height: 1px;
      margin: size(20) 0;
      margin-right: -#{size(80)};
    }
    &::after {
      content: '';
      width: 1px;
      height: size(400);
      background: #267f98;
      position: absolute;
      top: -#{size(450)};
      left: 50%;
    }
  }
  .swiper-box {
    width: 100%;
    .swiper-container {
      // padding: size(80) 0;
      // margin: size(100) 0;
      .slide {
        // height: 0;
        // height: 1000px;
        //height: 100vh;
        padding-bottom: 56%;
        background-size: cover;
        .content {
          position: absolute;
          z-index: 1;
          color: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 2;
          letter-spacing: 0.6px;
          font-size: size(25);

          .title {
            font-size: size(55);
            font-weight: 500;
            letter-spacing: 5.5px;
            margin-bottom: 35px;
            text-shadow: 0.1em 0.1em 0.4em black;
          }
        }
        .caption {
          position: absolute;
          z-index: 1;
          color: #fff;
          right: size(40);
          bottom: size(30);
          font-size: size(20);
          font-weight: 200;
          text-shadow: #000 0.1em 0.1em 0.1em;
        }
      }
      .swiper-button-prev {
        // left: size(500);
        left: 0;
        bottom: 0;
        width: size(80);
        height: size(80);
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }
      .swiper-button-next {
        right: 0;
        color: #fff;
        width: size(80);
        height: size(80);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }

      .swiper-pagination {
        padding-left: size(650);
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .fontSize-1\.8vh {
    font-size: size-m(12);
  }
  .section3 {
    background-color: #fff100;
    min-height: unset;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    // padding-top: size-m(50);
    .bg-img {
      background-image: url('./s5/style_line.svg');
      background-repeat: no-repeat;
      background-size: 115%;
      background-position: 66% 0%;
      width: 100%;
      z-index: 1;
      position: absolute;
      top: calc(70% - 68vw);
    }
    .info {
      text-align: left;
      width: 80%;
      margin-right: unset;
      margin-top: size-m(25);
      padding-top: size-m(25);
      height: auto;
      h2 {
        text-align: center;
        font-size: size-m(25.5);
        font-weight: 600;
        line-height: 1.5;
        height: size-m(70);
        font-family: 'Noto Serif TC', Noto Sans TC, serif;
      }
      .divider {
        width: 1px;
        height: size-m(50);
        margin-right: 0;
        margin: size-m(25) auto size-m(15) auto;
      }
      p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
      }
      &::after {
        content: '';
        width: 80vw;
        height: 1px;
        background: #267f98;
        position: absolute;
        left: 0;
        transform: unset;
        top: 0%;
        opacity: 1;
        animation: fade 2s;

        @keyframes fade {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
    .swiper-box {
      margin-top: size-m(0);
      width: 100%;
      .swiper-container {
        // padding-bottom: size-m(40);
        // margin-bottom: size-m(10);
        // margin-top: 0 !important;
        // padding-top: 0 !important;
        margin-bottom: 3px;
        .slide {
          height: 85vh;
          padding-bottom: 66%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          .content {
            top: 70%;
            width: 100%;
            font-size: size-m(13);

            .title {
              font-size: 18px;
              margin-bottom: 20px;
            }
          }
          .caption {
            position: absolute;
            z-index: 1;
            color: #fff;
            right: size-m(10);
            bottom: size-m(10);
            font-size: size-m(12);
            text-shadow: #000 0.1em 0.1em 0.1em;
            font-weight: 300;
          }
        }
        .swiper-button-prev {
          left: size-m(150);
          top: 95%;
          width: size-m(40);
          height: size-m(40);
          color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;

          &::after {
            content: '';
            left: 10vw;
            top: 1.5vh;
            width: 3%;
            height: 46%;
            position: absolute;
            background-image: url('./s3/arrow_line.svg');
          }
        }
        .swiper-button-next {
          right: size-m(150);
          top: 95%;
          width: size-m(40);
          height: size-m(40);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }

        .swiper-pagination {
          padding: 0;
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size-m(10);
            height: size-m(10);
            margin: 0 size-m(2);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section3',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMobile,
      activeSlide: {},
      slides: [
        {
          img: isMobile
            ? require('@/projects/rss/s3/slider_1.jpg')
            : require('@/projects/rss/s3/slider_1_pc.jpg'),
          content:
            '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
        },
        {
          img: isMobile
            ? require('@/projects/rss/s3/slider_2.jpg')
            : require('@/projects/rss/s3/slider_2_pc.jpg'),
          content:
            '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
        },
        {
          img: isMobile
            ? require('@/projects/rss/s3/slider_3.jpg')
            : require('@/projects/rss/s3/slider_3_pc.jpg'),
          content:
            '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        speed: 1200,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section3 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection3.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]

      // setTimeout(() => {
      //   $('.section3 [data-aos]').each((i, val) => {
      //     $(val).addClass('aos-animate')
      //   })
      //   setTimeout(() => {
      //     $('.section3 .hasdivider').addClass('showin')
      //   }, 200)
      //   setTimeout(() => {
      //     $('.section3 .divider').addClass('showin')
      //   }, 400)
      // }, 0)
    },
    prev() {
      console.log('prev')
      this.$refs.swiperSection3.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection3.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
    if (isMobile) {
      this.slides.push({
        img: require('@/projects/rss/s3/slider_3.jpg'),
        content:
          '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
      })
    }
  },

  created() {},
}
</script>
