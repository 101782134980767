<template>
  <div>
    <!-- Mobile -->
    <div class="section7 lg:hidden">
      <div class="info relative" data-aos="fade-up" data-aos-delay="400">
        <div class="subtitle" v-html="activeSlide.subtitle"></div>
        <h2 v-html="activeSlide.title"></h2>
        <div class="small-title" v-html="activeSlide.smallTitle"></div>
        <p v-html="activeSlide.content" class="mb-10"></p>
      </div>
      <div class="px-5 pb-5">
        <img loading="lazy" src="./s7/img.jpg" alt="" />
        <div class="font-medium text-lg caption">日勝幸福站 發現之旅.</div>
      </div>
      <img loading="lazy" src="./s7/line.svg" alt="" />
    </div>
    <!-- PC -->
    <div class="section7 hidden lg:flex flex-col">
      <div class="lg:flex w-full">
        <div class="w-1/2 relative">
          <img loading="lazy"
            data-aos="fade-up"
            data-aos-delay="400"
            src="./s7/img.jpg"
            alt=""
            class="w-full h-full object-cover"
          />
          <div class="absolute text-white bottom-6 right-6 font-medium text-xl caption">
            日勝幸福站 發現之旅.
          </div>
        </div>
        <div class="flex-grow flex flex-col justify-between">
          <div class="pt-36 mr-44 title1">
            <h2
              v-html="activeSlide.title"
              class="text-5xl text-primary-green-200 font-medium mb-10"
            ></h2>
            <p
              v-html="activeSlide.content"
              class="text-2xl leading-primary mb-28"
            ></p>
          </div>
          <img loading="lazy" src="./s7/style_line.svg" class="w-full" alt="" />
        </div>
      </div>
      <img loading="lazy" src="./s7/line.svg" alt="" />
    </div>
    <!-- <div class="flex">
        <div class=" relative">
          <img loading="lazy"
            data-aos="fade-up"
            data-aos-delay="400"
            src="./s7/img.jpg"
            alt=""
            class="w-full"
          />
        </div>
        <div class="info relative" data-aos="fade-up" data-aos-delay="400">
          <div class="subtitle" v-html="activeSlide.subtitle"></div>
          <h2 v-html="activeSlide.title"></h2>
          <div class="small-title" v-html="activeSlide.smallTitle"></div>
          <p v-html="activeSlide.content" class="mx-auto"></p>
        </div>
      </div> -->
  </div>
</template>
<style lang="scss">
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
.section7 {
  background-color: #fff100;
  width: 100%;
  overflow: hidden;
  position: relative;
  // background-image: url('./s7/style_line.svg');
  // background-repeat: no-repeat;
  // background-position: 100% 100%;
  // background-size: 40%;

  h2 {
    text-align: center;
    font-size: size(55);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 5px;
    color: #00693e;
    white-space: nowrap;
    font-family: Noto Sans TC, serif;
  }
  .title1 {
    margin-right: 0%;
  }

  .caption {
    position: absolute;
          z-index: 1;
          color: #fff;
          right: 2vw;
          bottom: 1vw;
          font-size: 16px;
          font-weight: 500;
          text-shadow: #000 0.1em 0.1em 0.1em;
  }
  .info {
    // position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    text-align: center;
    // background-color: rgba($color: #ededee, $alpha: 0.6);
    padding: size(150) size(80) size(60) size(50);
    .subtitle {
      font-size: size(29);
      color: #231815;
      line-height: 1.5;
      margin-bottom: size(25);
      font-family: Noto Sans TC, serif;
      font-weight: 500;
    }

    .small-title {
      text-align: center;
      font-size: size(39);
      color: #267f98;
      font-weight: 300;
    }
    .divider {
      position: relative;
      background: #fff;
      width: 200%;
      height: 1px;
      left: 0;
      margin-right: -100%;
      margin-top: size(25);
      margin-bottom: size(25);
    }
    p {
      text-align: center;
      font-size: size(22);
      letter-spacing: size(3);
      font-weight: 400;
      line-height: 1.5;
      color: #231815;
      max-width: size(570);
    }
    .s-nav {
      width: size(140);
      margin-top: size(25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prev,
      .next {
        width: size(24);
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }
  .swiper-box {
    position: relative;
    width: 100vw;
    .swiper-container {
      .slide {
        height: 0;
        padding-bottom: 56.25%;
        background-size: cover;
        .caption {
          position: absolute;
          z-index: 1;
          color: #fff;
          left: size(150);
          bottom: size(50);
          font-size: size(20);
          font-weight: 400;
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section7 {
    background-color: #fff100;
    min-height: unset;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-image: url('./s7/style_line.svg');
    background-repeat: no-repeat;
    background-position: 60% 6%;
    background-size: 120%;

    .caption {
     font-size: 12px !important;
     right: 9vw;
     bottom: 9vw;
      }

    h2 {
      text-align: center;
      font-size: size-m(18);
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 3px;
      font-family: Noto Sans TC, serif;
    }

    .info {
      position: relative;
      text-align: left;
      width: 100%;
      margin-left: unset;
      margin-top: size-m(70);
      margin-bottom: size-m(5);
      height: auto;
      padding: size-m(20);
      .subtitle {
        text-align: center;
        font-size: size-m(14.5);
        font-weight: 500;
        font-family: Noto Sans TC, serif;
      }
      .divider {
        display: none;
      }
      p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
        max-width: 100%;
        font-weight: 500;
      }
      .position-prev {
        top: 100%;
        left: 42%;
      }
      .position-next {
        top: 100%;
        right: 42%;
      }
    }
    .swiper-box {
      width: 100%;
      .swiper-container {
        padding: 0;
        margin: 0;
        .slide {
          height: 0;
          padding-bottom: 100%;
          background-size: cover;
          .caption {
            position: absolute;
            z-index: 1;
            color: #fff;
            right: size-m(10);
            bottom: size-m(10);
            font-size: size-m(12);
            font-weight: 400;
            text-align: right;
          }
        }
        // .swiper-button-prev {
        //   left: size-m(150);
        //   top: size-m(0);
        //   width: size-m(40);
        //   height: size-m(40);
        //   color: #fff;
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   background-position: left;
        //   // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        //   &::after {
        //     content: '';
        //   }
        // }
        // .swiper-button-next {
        //   right: size-m(150);
        //   width: size-m(40);
        //   height: size-m(40);
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   background-position: right;
        //   // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        //   &::after {
        //     content: '';
        //   }
        // }

        .swiper-pagination {
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size(18);
            height: size(18);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: '';
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: '';
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import 'swiper/css/swiper.css'

export default {
  name: 'section7',
  data() {
    return {
      isMobile,
      currentSlide: 0,
      activeSlide: {},
      slides: [
        {
          // subtitle: "璞真建設 建築摯美生活初衷",
          title: '／30年結構保固 唯有日勝生／',
          content: `41年日勝生集團 業績屢獲國家級建築獎章肯定<br/>更敢於領跑行業 率先提出長達「30年」結構保固<br/>並向保險公司投保責任保險 責任絕非口述`,
          img: [
            require('@/projects/rss/s7/slider_1.jpg'),
            require('@/projects/rss/s7/slider_2.jpg'),
            require('@/projects/rss/s7/slider_3.jpg'),
          ],
          caption: '圖說說明文字',
        },
      ],
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: '.position-next',
          prevEl: '.position-prev',
        },
        spaceBetween: 30,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    nextS() {
      this.currentSlide++
      if (this.currentSlide === this.slides.length) {
        this.currentSlide = 0
      }
      this.activeSlide = this.slides[this.currentSlide]
    },
    prevS() {
      this.currentSlide--
      if (this.currentSlide < 0) {
        this.currentSlide = this.slides.length - 1
      }

      this.activeSlide = this.slides[this.currentSlide]
    },
  },

  mounted() {
    this.activeSlide = this.slides[this.currentSlide]
  },

  created() {},
}
</script>
