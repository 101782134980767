<template>
  <div class="section5">
    <div class="bg-img">
      <div
        data-aos="fade-up"
        data-aos-delay="10"
        class="text-white z-10 text-xl lg:text-5xl mb-5 t1"
      >
        ／一樓庭院宅 與自然溫柔相棲／
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="10"
        class="text-white z-10 mb-10 lg:text-xl p"
      >
        <div>當多數人還在奔波 您已經可以選擇自在樂活</div>
        <div>坐擁專屬自己的庭院 蒔花弄草、賦閒沏茶⋯</div>
        <div>在自然中安神靜慮 溫柔無聲反倒是最治癒的力量</div>
      </div>
    </div>
    <span
      data-aos="fade-up"
      class="text-white absolute right-2 bottom-2 caption"
    >
      一樓庭院實景
    </span>
    <div class="relative w-full h-full">
      <img loading="lazy" class="hidden lg:block w-full h-full" src="./s5/img_pc.jpg" alt="" />
      <img loading="lazy" class="block lg:hidden w-full h-full" src="./s5/img.jpg" alt="" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
// .bg-line-img {
//   background-size: 120%;
//   top: 23%;
//   right: -3%;
// }
.img {
  max-height: 150%;
  max-width: 120%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  right: -30px;
  margin: auto;
}
.section5 {
  background-color: #fff100;
  background-image: url('./s5/bg_pc.jpg');
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 10%;
  position: relative;
  background-size: contain;

  .bg-img {
    background-image: url('./s5/style_line_pc.svg');
    background-repeat: no-repeat;
    background-position: 100% 103%;
    background-size: 50%;
    padding-bottom: 7%;
  }
  .caption {
    position: absolute;
    z-index: 1;
    color: #fff;
    right: 3vw;
    bottom: 2vw;
    font-size: 16px;
    font-weight: 500;
    text-shadow: #000 0.1em 0.1em 0.1em;
  }

  .t1 {
    padding-top: size(60);
    text-align: center;
    font-size: size(55);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 5px;
    white-space: nowrap;
    font-family: Noto Sans TC, serif;
  }
  .info {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    text-align: left;
    background-color: rgba($color: #ededee, $alpha: 0.6);
    padding: size(60) size(80) size(60) size(120);
    .subtitle {
      font-size: size(29);
      color: #231815;
      line-height: 1.5;
      margin-bottom: size(25);
      font-family: 'Noto Serif TC', Noto Sans TC, serif;
      font-weight: 600;
    }
    h2 {
      font-size: size(54);
      font-weight: 600;
      line-height: 1.2;
      color: #267f98;
      white-space: nowrap;
      margin-bottom: size(25);
      font-family: 'Noto Serif TC', Noto Sans TC, serif;
    }
    .small-title {
      text-align: center;
      font-size: size(39);
      color: #267f98;
      font-weight: 300;
    }
    .divider {
      position: relative;
      background: #fff;
      width: 200%;
      height: 1px;
      right: 0;
      margin-left: -100%;
      margin-top: size(25);
      margin-bottom: size(25);
    }
    p {
      font-size: size(22);
      letter-spacing: size(3);
      font-weight: 400;
      line-height: 1.5;
      color: #231815;
      max-width: size(570);
    }
    .s-nav {
      width: size(140);
      margin-top: size(25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prev,
      .next {
        width: size(24);
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }
  .swiper-box {
    position: relative;
    width: 100vw;
    .swiper-container {
      .slide {
        height: 0;
        padding-bottom: 56.25%;
        background-size: cover;
        .caption {
          position: absolute;
          z-index: 1;
          color: #000;
          right: size(150);
          bottom: size(50);
          font-size: size(20);
          font-weight: 400;
        }
      }
      .swiper-button-prev {
        left: size(50);
        width: size(80);
        height: size(80);
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }
      .swiper-button-next {
        right: size(50);
        color: #fff;
        width: size(80);
        height: size(80);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .img {
    width: 150%;
  }

  .section5 {
    background-color: #fff100;
    background-image: url('./s5/bg.jpg');
    background-size: 100%;
    min-height: 60vh;
    height: 80vh;
    height: auto;
    width: 100%;

    .p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 18px;
        font-weight: 500;
      }

    .bg-img {
      background-image: url('./s5/style_line.svg');
      background-position: 50% 0%;
      background-size: 125%;
      padding-top: 50%;
    }
    .t1 {
      text-align: center;
      font-size: size-m(18);
      font-weight: 500;
      line-height: 1.5;
      font-family: Noto Sans TC, serif;
      letter-spacing: 3px;
    }
    .p1 {
      font-size: size-m(12);
      letter-spacing: size(3);
      line-height: 18px;
      font-weight: 500;

      div {
        padding: 0px;
      }
    }

    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 3vw;
      bottom: 3vw;
      font-size: 12px;
      font-weight: 500;
      text-shadow: #000 0.1em 0.1em 0.1em;
    }

    .info {
      position: relative;
      text-align: left;
      width: 100%;
      margin-left: unset;
      margin-top: size-m(30);
      height: auto;
      padding: size-m(20);
      .subtitle {
        text-align: center;
        font-size: size-m(14.5);
        font-weight: 600;
      }
      h2 {
        text-align: center;
        font-size: size-m(25.2);
        font-weight: 600;
      }

      .divider {
        display: none;
      }
      p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
        max-width: 100%;
        font-weight: 500;
      }
    }
    .swiper-box {
      width: 100%;
      .swiper-container {
        padding: 0;
        margin: 0;
        .slide {
          height: 0;
          padding-bottom: 100%;
          background-size: cover;
          .caption {
            position: absolute;
            z-index: 1;
            color: #000;
            right: size-m(10);
            bottom: size-m(10);
            font-size: size-m(12);
            font-weight: 400;
          }
        }
        .swiper-button-prev {
          left: size-m(20);
          width: size-m(40);
          height: size-m(40);
          color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }
        .swiper-button-next {
          right: size-m(20);
          width: size-m(40);
          height: size-m(40);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size(18);
            height: size(18);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import 'swiper/css/swiper.css'
import $ from 'jquery'

export default {
  name: 'section5',
  data() {
    return {
      isMobile,
      currentSlide: 0,
      activeSlide: {},
 /*     slides: [
        {
          subtitle: '璞真建設 建築摯美生活初衷',
          title: '讓建築好好地長<br>就像那些很紮實的大樹',
          content: `2002年成立，璞真像棵樹，也像種樹的人，作建築本心，對環境，對人，密密融合；對土地，念念不忘。致力發揮土地最高價值，秉持自然生態與居住環境和諧共生。`,
          img: [require('@/projects/rss/s5/1.jpg')],
          caption: '3D 情境示意圖',
        },
        {
          subtitle: '璞真建22設 建築摯美生活初衷',
          title: '讓建築好好地長<br>就像那些很紮實的大樹',
          content: `2002年成立，璞真像棵樹，也像種樹的人，作建築本心，對環境，對人，密密融合；對土地，念念不忘。致力發揮土地最高價值，秉持自然生態與居住環境和諧共生。`,
          img: [require('@/projects/rss/s5/1.jpg')],
          caption: '3D 情境示意圖',
        },
      ],*/
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
      scrollValue: -200,
    }
  },

  methods: {
    handleScroll(e) {
      console.warn(e)
      console.log(e.deltaY)
      if (e.deltaY > 0) {
        this.scrollValue = this.scrollValue + 2
      } else {
        this.scrollValue = this.scrollValue - 2
      }
    },
    scrolling() {
      const _this = this
      $(window).on('scroll', function () {
        const scrollPosition = $(window).scrollTop()
        _this.$nextTick(() => {
          const temp = -1000 + scrollPosition / 5
          _this.scrollValue = temp
        })
      })
    },
    nextS() {
      this.currentSlide++
      if (this.currentSlide === this.slides.length) {
        this.currentSlide = 0
      }
      this.activeSlide = this.slides[this.currentSlide]
    },
    prevS() {
      this.currentSlide--
      if (this.currentSlide < 0) {
        this.currentSlide = this.slides.length - 1
      }

      this.activeSlide = this.slides[this.currentSlide]
    },
  },

  mounted() {
    this.activeSlide = this.slides[this.currentSlide]
    this.scrolling()
  },
}
</script>
