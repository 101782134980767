<template>
  <div class="section6">
    <div class="bg-img relative" data-aos="fade-up" data-aos-delay="400">
      <div
        class="text-center text-xl lg:text-5xl text-primary-green-200 font-primary font-medium lg:mb-10 mb-4 h2"
        v-html="slides[0].title"
      />
      <div
        v-html="slides[0].content"
        class="mb-10 lg:mb-0 lg:mx-auto text-sm md:text-base lg:text-2xl lg:leading-primary p"
      />
    </div>
    <!-- pc carousel -->
    <div v-if="!isMobile" class="custom-slide mb-20">
      <div class="detection left-detection" @click="prevSlide"></div>
      <div class="detection right-detection" @click="nextSlide"></div>
      <swiper
        ref="swiperSection6"
        :options="customSwiperOptions"
        :freeMode="true"
        @slideChangeTransitionStart="customSlidechangeStart"
        @slideChangeTransitionEnd="customSlidechangeEnd"
      >
        <swiper-slide
          class="slide"
          v-for="(slide, i) in [...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array, ...slides[0].array]"
          v-bind:key="i"
          v-bind:style="{
            backgroundImage: `url(${slide.img})`,
          }"
        >
          <div id="caption" class="caption">
            {{ slide.caption }}
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <!-- mobile carousel -->
    <div class="swiper-box" data-aos="fade-up" v-if="isMobile">
      <swiper
        ref="swiperSection6"
        :options="swiperOptions"
        :slidesPerView="3"
        :spaceBetween="30"
        :freeMode="true"
        @slideChangeTransitionStart="slidechange"
      >
        <swiper-slide
          class="slide"
          v-for="(slide, i) in slides[0].array"
          v-bind:key="i"
          v-bind:style="{
            backgroundImage: `url(${slide.img})`,
          }"
        >
          <div id="caption" class="caption">
            {{ slide.caption }}
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import $ from 'jquery'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'section6',

  components: {
    Swiper,
    SwiperSlide,
    VueSlickCarousel,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      settings: {
        centerMode: true,
        centerPadding: '20px',
        focusOnSelect: true,
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 20000,
        autoplaySpeed: 20000,
        pauseOnHover: false,
        pauseOnFocus: false,
        cssEase: 'linear',
      },
      isMobile,
      currentSlide: 0,
      activeSlide: {},
      slides: [
        {
          // subtitle: "璞真建設 建築摯美生活初衷",
          title: '／6米樓中樓 雙層空間超坪效／',
          content: `室內樓高6米 三代同堂、待客會友顯氣昀<br />雙層使用空間 放大坪效 享獨立完整機能<br />讓房子一間二顧 想要與需要 一樣都不少`,
          array: [
            {
              img: isMobile
                ? require('@/projects/rss/s6/slider_4.jpg')
                : require('@/projects/rss/s6/slider_4_pc.jpg'),
              caption: '樓高6米實品屋',
            },
            {
              img: isMobile
                ? require('@/projects/rss/s6/slider_1.jpg')
                : require('@/projects/rss/s6/slider_1_pc.jpg'),
              caption: '樓高6米實品屋',
            },
            {
              img: isMobile
                ? require('@/projects/rss/s6/slider_2.jpg')
                : require('@/projects/rss/s6/slider_2_pc.jpg'),
              caption: '樓高6米實品屋',
            },
            {
              img: isMobile
                ? require('@/projects/rss/s6/slider_3.jpg')
                : require('@/projects/rss/s6/slider_3_pc.jpg'),
              caption: '樓高6米實品屋',
            },
            
          ],
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section3 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
      customSwiperOptions: {
        allowTouchMove: false,
        speed: 2000,
        slidesPerView: 2.5,
        spaceBetween: 50,
        centeredSlides: true,
        loop: true,
        freeMode: true,
        pagination: {
          el: '.section3 .swiper-pagination',
          clickable: true,
        },
        autoplay: false,
      },
      slideInterval: null,
      currentX: 0,
      slideWidth: 0,
      moveSpeed: 0.35,
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection6.$swiper.realIndex
      this.activeSlide = this.slides[0].array[activeIndex]
    },
    customSlidechangeStart() {},
    customSlidechangeEnd() {},
    initSlide() {
      const style = window.getComputedStyle($('.section6 .swiper-wrapper')[0])
      const matrix = new WebKitCSSMatrix(style.transform)
      this.currentX = matrix.m41
      this.slideWidth = $(window).width() / 2.5

      setInterval(() => {
        this.currentX = this.currentX - this.moveSpeed
        if (Math.abs(this.currentX) > 60 * this.slideWidth) {
          this.currentX = 0
          $('.section6 .swiper-wrapper').css({
            'transition-duration': '500ms',
            transform: 'translate3d(' + this.currentX + 'px, 0px, 0px)',
          })
        } else {
          $('.section6 .swiper-wrapper').css({
            transform: 'translate3d(' + this.currentX + 'px, 0px, 0px)',
          })
        }
      }, 10)
    },
    nextSlide() {
      const s = Math.ceil(Math.abs(this.currentX) / this.slideWidth)
      this.currentX = s * this.slideWidth * -1 - 250

      $('.section6 .swiper-wrapper').css({
        'transition-duration': '500ms',
        transform: 'translate3d(' + this.currentX + 'px, 0px, 0px)',
      })
    },
    prevSlide() {
      const s = Math.round(Math.abs(this.currentX) / this.slideWidth) - 1
      this.currentX = s * this.slideWidth * -1 - 250
      $('.section6 .swiper-wrapper').css({
        'transition-duration': '500ms',
        transform: 'translate3d(' + this.currentX + 'px, 0px, 0px)',
      })
    },
  },
  mounted() {
    this.activeSlide = this.slides[0].array[0]
    if (!this.isMobile) {
      this.initSlide()
    }
  },

  created() {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';
::v-deep .slick-slide {
  padding: 0 2vw;
}
::v-deep .carousel-3d-container {
  height: 80vh !important;
  width: 100vw;
  margin-top: 15vw;
}
::v-deep .carousel-3d-slide.current,
.carousel-3d-slide.right-1,
.carousel-3d-slide.left-1 {
  width: 100% !important;
  height: 100% !important;
}
::v-deep .carousel-3d-slider {
  // animation: leftMove 200s linear infinite;
  animation: move 200s linear infinite;
  height: 100% !important;
  width: 70vw !important;
}

::v-deep .carousel-3d-slide img {
  width: 100%;
  height: 100%;
}

@keyframes move {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-3000px);
  }
}
@keyframes leftMove {
  0% {
    transform: translateX(0px);
  }

  10% {
    transform: translateX(-1000px);
  }

  20% {
    transform: translateX(-2000px);
  }

  30% {
    transform: translateX(-3000px);
  }

  40% {
    transform: translateX(-4000px);
  }

  50% {
    transform: translateX(-5000px);
  }

  60% {
    transform: translateX(-6000px);
  }

  70% {
    transform: translateX(-7000px);
  }

  80% {
    transform: translateX(-8000px);
  }

  90% {
    transform: translateX(-9000px);
  }

  100% {
    transform: translateX(-10000px);
  }
}
// #caption {
//   position: absolute;
//   z-index: 1;
//   color: #fff;
//   right: 2vw;
//   bottom: 2vw;
//   font-size: 16px;
//   font-weight: 500;
//   text-shadow: #000 0.1em 0.1em 0.1em;
// }
/* 螢幕尺寸標準 */
.section6 {
  background-color: #fff100;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .custom-slide {
    position: relative;
    .detection {
      position: absolute;
      z-index: 100;
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      top: 0;
      width: 40%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      transition: all 0.5s;

      &.left-detection {
        left: 0;
      }
      &.right-detection {
        right: 0;
      }
    }

    .swiper-wrapper {
      will-change: transform;
      transition: all 1s;
      .slide {
        position: relative;
        height: 0;
        padding-bottom: 24%;
        background-size: cover;
        background-position: 0%;
        background-repeat: no-repeat;
        animation: bgmove 15s alternate-reverse infinite linear;

        @keyframes bgmove {
          from {
            background-position: 0%;
          }
          to {
            background-position: 100%;
          }
        }
      }
    }

    @keyframes slide {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }

  #caption {
    position: absolute;
    z-index: 1;
    color: #fff;
    right: 2vw;
    bottom: 1vw;
    font-size: 16px;
    font-weight: 500;
    text-shadow: #000 0.1em 0.1em 0.1em;
  }
  // #caption_pc {
  //   position: absolute;
  //   z-index: 1;
  //   color: #fff;
  //   right: 2vw;
  //   bottom: 2vw;
  //   font-size: 16px;
  //   font-weight: 500;
  //   text-shadow: #000 0.1em 0.1em 0.1em;
  // }

  .bg-img {
    background-image: url('./s6/style_line_pc.svg');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 100% 100%;
    padding: 8% 0;
  }
  .info {
    p {
      text-align: center;
      // font-size: size(22);
      letter-spacing: size(3);
      font-weight: 400;
      // line-height: 1.5;
      color: #231815;
      max-width: size(700);
    }
    .s-nav {
      width: size(140);
      margin-top: size(25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prev,
      .next {
        width: size(24);
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }
  .swiper-box {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    margin-top: 40%;
    .swiper-container {
      .slide {
        height: 0;
        // padding-bottom: 40%;
        background-size: contain;
        .caption {
          position: absolute;
          z-index: 1;
          color: #fff;
          right: 3%;
          bottom: 2%;
          font-size: size(20);
          font-weight: 400;
        }
      }

      .swiper-button-prev {
        // left: size(500);
        left: 3%;
        bottom: 0;
        width: size(80);
        height: size(80);
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }
      .swiper-button-next {
        // right: 0;
        color: #fff;
        width: size(80);
        height: size(80);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .caption {
    position: absolute;
    z-index: 1;
    color: #fff;
    right: 4vw;
    bottom: 4vw;
    font-size: 12px;
    font-weight: 500;
    text-shadow: #000 0.1em 0.1em 0.1em;
    // position: absolute;
    // z-index: 1;
    // color: #fff;
    // right: size-m(10);
    // bottom: size-m(10);
    // font-size: 12px;
    // text-shadow: #000 0.1em 0.1em 0.1em;
    // font-weight: 300;
  }
  .section6 {
    background-color: #fff100;
    min-height: unset;
    height: auto;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // flex-direction: column;

    .p {
      margin-top: size-m(20);
      font-size: size-m(12);
      letter-spacing: size(3);
      line-height: 18px;
      font-weight: 500;
    }
    .h2 {
      letter-spacing: 3px;
    }

    #caption {
      font-size: 12px !important;
      right: 4vw;
      bottom: 3vw;
    }

    .bg-img {
      background-image: url('./s6/style_line_pc.svg');
      background-repeat: no-repeat;
      background-size: 120%;
      background-position: 58% 25%;
      padding: 21% 0 5% 0;
    }
    h2 {
      text-align: center;
      font-size: size-m(18);
      font-weight: 600;
      line-height: 1.5;
      font-family: Noto Sans TC, serif;
      margin-bottom: 20px !important;
    }

    .info {
      position: relative;
      text-align: left;
      right: 0;
      width: 100%;
      margin-left: unset;
      margin-top: size-m(40);
      margin-bottom: size-m(30);
      height: auto;
      padding: size-m(20);
      .subtitle {
        text-align: center;
        font-size: size-m(14.5);
        font-weight: 600;
      }
      h2 {
        text-align: center;
        font-size: size-m(20);
        font-weight: 600;
        letter-spacing: 3px;
      }
      .divider {
        display: none;
      }
      p {
        margin-bottom: 10px;
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 1.5;
        max-width: 100%;
        font-weight: 500;
      }
      .position-prev {
        top: 100%;
        left: 42%;
      }
      .position-next {
        top: 100%;
        right: 42%;
      }
    }
    .swiper-box {
      margin-top: size-m(0);
      width: 100%;
      .swiper-container {
        margin-bottom: 3px;
        .slide {
          height: 85vh;
          padding-bottom: 66%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          // .caption {
          //   position: absolute;
          //   z-index: 1;
          //   color: #fff;
          //   right: size-m(10);
          //   bottom: size-m(10);
          //   font-size: 12px;
          //   text-shadow: #000 0.1em 0.1em 0.1em;
          //   font-weight: 300;
          // }
        }
        .swiper-button-prev {
          left: 1;
          top: 50%;
          width: size-m(40);
          height: size-m(40);
          color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;
        }
        .swiper-button-next {
          right: 2;
          top: 50%;
          width: size-m(40);
          height: size-m(40);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
        }

        .swiper-pagination {
          padding: 0;
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size-m(10);
            height: size-m(10);
            margin: 0 size-m(2);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: '';
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: '';
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>
