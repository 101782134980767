<template>
  <div class="section2">
    <img loading="lazy" src="./s1/line.svg" class="hidden lg:block" alt="" />
    <div
      class="pt-28 pb-28 text-center bg-img bg-no-repeat flex justify-center items-center w-screen"
    >
      <div class="info" :key="activeSlide.title + '1'">
        <h2 v-html="activeSlide.title" data-aos="fade-up"></h2>
        <p
          class="mt-0 lg:mt-10 leading-primary lg:text-2xl"
          v-html="activeSlide.content"
          data-aos="fade-up"
          data-aos-delay="400"
        ></p>
      </div>
    </div>
    <div class="relative w-full">
      <img loading="lazy"
        data-aos="fade-up"
        data-aos-delay="400"
        class="mb-1 lg:object-cover lg:w-full lg:h-full"
        :src="isMobile ? require('./s2/1_mo.jpg') : require('./s2/1.jpg')"
        alt=""
      />
      <span
        data-aos="fade-up"
        class="text-white absolute right-2 bottom-2 caption"
      >
        板橋浮洲
      </span>
    </div>
<!--
    <div class="relative w-full">
      <img loading="lazy"
        data-aos="fade-up"
        data-aos-delay="400"
        class="mb-1 lg:object-cover lg:w-full lg:h-full"
        :src="isMobile ? require('./s2/2_mo.jpg') : require('./s2/2.jpg')"
        alt=""
      />
      <span
        data-aos="fade-up"
        class="text-white absolute right-2 bottom-2 caption"
      >
        日勝幸福站 發現之旅.
      </span>
    </div>
    -->
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.bg-img {
  background-image: url('./s2/style_line.svg');
  background-size: 50%;
  background-position: 115% 138%;
}
/* 螢幕尺寸標準 */
.section2 {
  background-color: #fff100;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .info {
    text-align: center;
    width: 100vw;
    // margin-left: size(80);
    // margin-top: size(160);
    height: size(280);
    h2 {
      text-align: center;
      font-size: size(55);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 5px;
      color: #00693e;
      white-space: nowrap;
      font-family: Noto Sans TC, serif;
    }
    .divider {
      background: #009be4;
      width: size(560);
      height: 1px;
      margin: size(20) 0;
      margin-left: -#{size(80)};
    }
    p {
      letter-spacing: size(3);
      font-weight: 400;
      // line-height: 1.5;
      color: #231815;
    }
  }
  .caption {
    position: absolute;
    z-index: 1;
    color: #fff;
    right: 3vw;
    bottom: 2vw;
    font-size: 16px;
    font-weight: 500;
    text-shadow: #000 0.1em 0.1em 0.1em;
  }
  .swiper-box {
    width: size(1278);
    .swiper-container {
      padding: size(80) 0;
      // margin: size(100) 0;
      .slide {
        height: 0;
        padding-bottom: 66%;
        background-size: cover;
        .caption {
          position: absolute;
          z-index: 1;
          color: #fff;
          right: 3vw;
          bottom: 3vw;
          font-size: 16px;
          font-weight: 500;
          text-shadow: #000 0.1em 0.1em 0.1em;
        }
      }
      .swiper-button-prev {
        left: size(50);
        width: size(80);
        height: size(80);
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }
      .swiper-button-next {
        right: size(50);
        color: #fff;
        width: size(80);
        height: size(80);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

        &::after {
          content: '';
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          margin: 0 size(6);
          border-radius: 0;
          background: transparent;
          border: 1px solid #267f98;
          width: size(18);
          height: size(18);
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background: #267f98;
          }
        }
      }
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .bg-img {
    background-image: url('./s2/style_line_mo.svg');
    background-position: bottom;
    background-size: 120%;
    height: 40vh;
  }
  

  .section2 {
    background-color: #fff100;
    min-height: unset;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .caption {
     font-size: 12px !important;
     right: 4vw;
     bottom: 3vw;
      }

    .info {
      text-align: center;
      width: 80%;
      // margin-left: unset;
      // margin-top: size-m(30);
      height: auto;
      h2 {
        text-align: center;
        font-size: size-m(18);
        font-weight: 600;
        line-height: 1.5;
        font-family: Noto Sans TC, serif;
      }
      .divider {
        display: none;
      }
      p {
        margin-top: size-m(20);
        font-size: size-m(12);
        letter-spacing: size(3);
        line-height: 18px;
        font-weight: 500;
      }
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: size-m(10);
        bottom: size-m(10);
        font-size: 12px;
        text-shadow: #000 0.1em 0.1em 0.1em;
        font-weight: 300;
      }
    }
    .swiper-box {
      width: 100%;
      .swiper-container {
        padding: 0;
        // margin: size-m(20) 0;
        margin-bottom: 3px;
        .slide {
          height: 0;
          padding-bottom: 100%;
          background-size: cover;
          .caption {
            position: absolute;
            z-index: 1;
            color: #fff;
            right: size-m(10);
            bottom: size-m(10);
            font-size: 12px;
            text-shadow: #000 0.1em 0.1em 0.1em;
            font-weight: 300;
          }
        }
        .swiper-button-prev {
          left: size-m(20);
          width: size-m(40);
          height: size-m(40);
          color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }
        .swiper-button-next {
          right: size-m(20);
          width: size-m(40);
          height: size-m(40);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

          &::after {
            content: '';
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            border-radius: 0;
            background: transparent;
            border: 1px solid #267f98;
            width: size(18);
            height: size(18);
            opacity: 1;
            &.swiper-pagination-bullet-active {
              background: #267f98;
            }
          }
        }
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import 'swiper/css/swiper.css'

export default {
  name: 'section2',
  data() {
    return {
      isMobile,
      activeSlide: {},
      slides: [
        {
          title: '／綠色新豐景 板橋再發現／',
          content: `「日勝幸福站 發現之旅.」3.3萬坪理想生活園區<br/>旁鄰台北最美人工濕地，享受綠樹治里，不遠市心<br/>還有台藝大、華僑高中、大觀國中小，文風包圍<br/>板南線亞東站、台鐵浮洲站、台65線，瞬連繁華`,
          img: isMobile
            ? require('@/projects/rss/s2/1_mo.jpg')
            : require('@/projects/rss/s2/1.jpg'),
          caption: '板橋浮洲.',
        },
        {
          title: '／綠色新豐景 板橋再發現／',
          content: `「日勝幸福站 發現之旅.」3.3萬坪理想生活園區<br/>旁鄰台北最美人工濕地，享受綠樹治里，不遠市心<br/>還有台藝大、華僑高中、大觀國中小，文風包圍<br/>板南線亞東站、台鐵浮洲站、台65線，瞬連繁華`,
          img: isMobile
            ? require('@/projects/rss/s2/1_mo.jpg')
            : require('@/projects/rss/s2/1.jpg'),
          caption: '板橋浮洲.',
        },
      ],
      slides2: [
        {
          title: '／綠色新豐景 板橋再發現／',
          content: `「日勝幸福站 發現之旅.」3.3萬坪理想生活園區<br/>旁鄰台北最美人工濕地，享受綠樹治里，不遠市心<br/>還有台藝大、華僑高中、大觀國中小，文風包圍<br/>板南線亞東站、台鐵浮洲站、台65線，瞬連繁華`,
          img: isMobile
            ? require('@/projects/rss/s2/2_mo.jpg')
            : require('@/projects/rss/s2/2.jpg'),
          caption: '日勝幸福站 發現之旅.',
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section2 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 10000,
        },
      },
    }
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },

  created() {},
}
</script>
