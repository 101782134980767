export default {
  address: '接待中心：新北市板橋區合宜路145號',
  googleSrc:
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3616.0389416826683!2d121.4485528!3d24.9987927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468033e523642db%3A0x3bc210424ddaf9fc!2z5pel5Yud5bm456aP56uZIOeZvOePvuS5i-aXhS4!5e0!3m2!1szh-TW!2stw!4v1639722882370!5m2!1szh-TW!2stw',
  googleLink: 'https://g.page/xingarden',
  phone: '02-8275-2233',
  fbLink: 'https://www.facebook.com/RadiumLife/',
  fbMessage: 'https://m.me/RadiumLife/',
  caseName: '日勝幸福站',
  indigatorLength: 10,
  houseInfos: [
    ['產品規劃 /', '獨賣一樓庭園宅 60-70坪<br/>推薦一樓金店面38 、48坪'],
    ['投資建設 / ', '日勝生活科技股份有限公司'],
    ['規劃戶數 / ', '住宅58戶、店面26戶'],
    ['建築代銷 / ', '新聯陽機構˙新聯祥廣告'],
    ['經紀人證號 / ', '張展榮(110)雲縣地字000277號'],
    [
      '使照號碼 / ',
      '104板使字第00057號<br/>106板使字第00250號<br/>107板使字第00006號'
    ]
  ],

  gtmCode: ['F89XZS7','W62V6SF'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: '東區核心重逢太平洋精品之作<br>不必晝想夜夢 只須及早把握'
  }
}
